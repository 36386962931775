//import vue router
import { createRouter, createWebHistory } from 'vue-router'
import PageNotFound from '@/views/404.vue'
//define a routes
const routes = [
    {
        path: '/',
        name: 'login.index',
        meta: {
            layout: 'login',
        },
        component: () => import(  '@/views/Login.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard.index',
        meta: {
            layout: 'app',
        },
        component: () => import(  '@/views/dashboard/index.vue')
    },
    
    {
        path: '/profile',
        name: 'profile.index',
        meta: {
            layout: 'app',
        },
        component: () => import(  '@/views/profile/Index.vue')
    },
   
    {
        path: '/lupapw',
        name: 'lupapw.index',
        meta: {
            layout: 'error',
        },
        component: () => import(  '@/views/lupapw/index.vue')
    },
    
  
    {
        path: '/barang',
        name: 'barang.index',
        meta: {
            layout: 'app',
        },
        component: () => import(  '@/views/barang/Index.vue')
    },
    {
        path: '/barang/riwayat_stok',
        name: 'barang.riwayat_stok',
        meta: {
            layout: 'app',
        },
        component: () => import('@/views/barang/StockHistory.vue')
    },
    {
        path: '/print',
        name: 'print.index',
        meta: {
            layout: 'error',
        },
        component: () => import(  '@/views/print/print.vue')
    },


    {   
        path: "/:catchAll(.*)*",  
        meta: {
            layout: 'error',
        }, 
        component : PageNotFound,
     }
]

//create router
const router = createRouter({
    history: createWebHistory(),
    routes,  // config routes
    linkActiveClass: 'active'
})


export default router