import { createApp } from "vue";
import App from "./App.vue";
import Default from "./Default.vue";
import Login from "./Login.vue";
import Error from "./Error.vue";
//import router
import router from "./routers";

//import Bootstrap, Popper, jQuery
// import 'bootstrap/dist/css/bootstrap.css'
import "jquery/dist/jquery.min";
// import 'popper.js/dist/popper.min'
// import 'bootstrap/dist/js/bootstrap.min'
import "@fortawesome/fontawesome-free/css/all.min.css";
import "boxicons/css/boxicons.min.css";
import VueApexCharts from "vue3-apexcharts";
import Toaster from "@meforma/vue-toaster";
import VueBarcode from "@chenfengyuan/vue-barcode";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const app = createApp(App);
app.config.globalProperties.globalApiUrl = "http://localhost:8000/";
// app.config.globalProperties.globalApiUrl = "http://192.168.18.23:8800/";
// app.config.globalProperties.globalApiUrl = "https://brayandiesel.com/";
app.component("app-layout", Default);
app.component("login-layout", Login);
app.component("error-layout", Error);
app.component("v-select", vSelect);
app.component(VueBarcode.name, VueBarcode);
app.use(VueApexCharts);
app.use(Toaster);
//use vue router
app.use(router);

app.mount("#app");

// import Vue from"vue";
// import App from"./App.vue";
// import router from './routers';

// import Default from "./Default.vue";
// import Login from "./Login.vue";

// Vue.component('app-layout', Default);
// Vue.component('login-layout', Login);

// Vue.config.productionTip = false;

// new Vue({
//     router,
//     render : h => h(App)
// }).$mount("#app");
